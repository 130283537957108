import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { CssBaseline, ThemeProvider } from '@mui/material';

import { theme } from './styles/theme';

import { FlightPlanLayout } from './features/flight-plan/FlightPlanLayout';
import { FlightPlanBriefing } from './features/flight-plan/FlightPlanBriefing';
import { FlightPlanOFP } from './features/flight-plan/FlightPlanOFP';
import { FlightPlanProcedure } from './features/flight-plan/FlightPlanProcedure';
import { FlightPlanUsername } from './features/flight-plan/FlghtPlanUsername';
import { CurrentFlightPage } from './features/current-flight/CurrentFlightPage';

// Polyfill
if (!Object.hasOwn) {
  Object.hasOwn = (obj, prop) =>
    Object.prototype.hasOwnProperty.call(obj, prop);
}

export const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <BrowserRouter>
        <Routes>
          <Route path="/" element={<FlightPlanLayout />}>
            <Route index element={<FlightPlanBriefing />} />
            <Route path="ofp" element={<FlightPlanOFP />} />
            <Route path="flight" element={<CurrentFlightPage />} />
            <Route path="procedures" element={<FlightPlanProcedure />} />
            <Route path="simbrief" element={<FlightPlanUsername />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
};
