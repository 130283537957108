import React from 'react';

import Box from '@mui/material/Box';

import { useAppSelector } from '../../state/hooks';

export const Username: React.FC = (): JSX.Element => {
  const username = useAppSelector((state) => state.flightPlan.simbriefUsername);

  return (
    <>
      {username && (
        <Box
          className="cycle"
          sx={{
            color: 'text.secondary',
            px: 1,
            py: 0.5,
            fontSize: '1.5rem',
            fontFamily: '"Barlow Condensed"',
          }}
        >
          USERNAME
          <Box
            sx={{
              display: 'inline',
              color: 'text.primary',
              fontWeight: 'bold',
              mx: 1,
            }}
          >
            {username}
          </Box>
        </Box>
      )}
    </>
  );
};
