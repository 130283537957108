import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { Cycle } from '../../features/planning/entities/Cycle';
import { AtcEvent } from '../../features/sop/entities/AtcEvent';
import { Procedure } from '../../features/sop/entities/Procedure';

import { Option } from '../../app/components/form/Option';

export const backendApiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_BASEURL}/api/v1`,
  }),
  tagTypes: [
    'Action',
    'Aircraft',
    'Airline',
    'Company',
    'FlightDispatch',
    'Sop',
    'Pilot',
    'Procedures',
    'Procedure',
    'Route',
    'User',
  ],
  endpoints: (builder) => ({
    fetchApiHealth: builder.query<{ status: string; version: string }, void>({
      query() {
        return `/health`;
      },
    }),
    getCurrentCycle: builder.query<Cycle, void>({
      query() {
        return `/cycles/current`;
      },
    }),
    fetchProcedureByType: builder.query<Procedure, { type: string }>({
      query({ type }) {
        return `/sops/public/types/${type}`;
      },
      transformResponse(response: Procedure) {
        response.content.sections = response.content.sections.map(
          (section) => ({
            ...section,
            items: section.items.map((item) => {
              if (item.tasks && item.tasks.length > 0) {
                return {
                  ...item,
                  tasks: item.tasks.map((task) => ({
                    ...task,
                    completed: false,
                  })),
                };
              } else if (item.checklistItems && item.checklistItems.length) {
                return {
                  ...item,
                  checklistItems: item.checklistItems.map((checklistItem) => ({
                    ...checklistItem,
                    completed: false,
                  })),
                };
              }
              return {
                ...item,
              };
            }),
          }),
        );
        return response;
      },
      providesTags: ['Procedure'],
    }),
    fetchAtcEvents: builder.query<
      AtcEvent[],
      { rules: string; requestType?: string; language?: string }
    >({
      query({ rules, requestType, language = 'en' }) {
        if (requestType !== undefined) {
          return `/atc-events?rules=${rules}&requestType=${requestType}&language=${language}`;
        }

        return `/atc-events?rules=${rules}&language=${language}`;
      },
      transformResponse(response: (AtcEvent & Option)[]) {
        return response.map((atcEvent) => ({
          ...atcEvent,
          label: `${atcEvent.defaultTitle}`,
        }));
      },
    }),
  }),
});

export const {
  useGetCurrentCycleQuery,
  useFetchApiHealthQuery,
  useFetchAtcEventsQuery,
  useFetchProcedureByTypeQuery,
} = backendApiSlice;
