import React, { useEffect, useState } from 'react';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { AircraftState, FlightInfo, SimulatorData } from './SimulatorData';
import { InfoPanel } from '../../app/components/ui/InfoPanel';

enum WebSocketState {
  OPENED = 1,
  ONERROR = 2,
  CLOSED = 3,
}

export const CurrentFlightPage: React.FC = (): JSX.Element => {
  const [websocketState, setWebsocketState] = useState<WebSocketState>(
    WebSocketState.CLOSED,
  );

  const [currentAircraftState, setCurrentAircraftState] =
    useState<AircraftState>(null);

  const [currentFlightInfo, setCurrentFlightInfo] = useState<FlightInfo>(null);
  const [socketUrl] = useState('ws://localhost:45929/');

  const { lastJsonMessage, readyState } = useWebSocket(socketUrl, {
    onOpen: () => setWebsocketState(WebSocketState.OPENED),
    onClose: () => setWebsocketState(WebSocketState.CLOSED),
    onError: () => setWebsocketState(WebSocketState.ONERROR),
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: 3000,
  });

  useEffect(() => {
    if (lastJsonMessage !== null) {
      setCurrentAircraftState((lastJsonMessage as SimulatorData).AircraftState);
      setCurrentFlightInfo((lastJsonMessage as SimulatorData).FlightInfo);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    switch (websocketState) {
      case WebSocketState.OPENED:
        break;
      case WebSocketState.ONERROR:
        break;
      case WebSocketState.CLOSED:
        break;
      default:
        break;
    }
  }, [websocketState]);

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Connected',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[readyState];

  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h2" component="h2" className="title">
        Current Flight
        <Chip
          sx={{ ml: 2 }}
          label={connectionStatus}
          size="small"
          color={readyState === ReadyState.OPEN ? 'success' : 'secondary'}
        />
      </Typography>

      <div className="aircraft-state">
        {currentAircraftState && readyState === ReadyState.OPEN ? (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <InfoPanel title="Aircraft">
                  <Grid container spacing={2} sx={{ m: 0.5 }}>
                    <Grid item xs={3}>
                      <Box className="data-display">
                        <Box>Aircraft</Box>
                        <Box>{currentFlightInfo.Title}</Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="data-display">
                        <Box>Callsign</Box>
                        <Box>{currentFlightInfo.AtcFlightNumber}</Box>
                      </Box>
                    </Grid>
                  </Grid>
                </InfoPanel>
              </Grid>
              <Grid item xs={12}>
                <InfoPanel title="Flight Data">
                  <Grid container spacing={2} sx={{ m: 0.5 }}>
                    <Grid item xs={3}>
                      <Box className="data-display">
                        <Box>Altitude</Box>
                        <Box>{currentAircraftState.AltitudeIndicated} ft</Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="data-display">
                        <Box>Ground Speed</Box>
                        <Box>{currentAircraftState.GroundSpeed} kts</Box>
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="data-display">
                        <Box>Fuel Quantity</Box>
                        <Box>
                          {(
                            Math.round(
                              currentAircraftState.FuelQuantityKg * 100,
                            ) / 100
                          ).toFixed(2)}{' '}
                          KG
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </InfoPanel>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Typography variant="body1" component="h4">
              Loading...
            </Typography>
          </>
        )}
      </div>
    </Box>
  );
};
